import React from 'react';
import useFelaEnhanced from '../../hooks/useFelaEnhanced';

import * as felaRules from './Paragraph.styles';
import { RulesExtend } from '../../gatsby-theme-fela/styles/theme';

export interface ParagraphProps {
  extend?: RulesExtend<typeof felaRules>;
  allowHtml?: boolean;
}

const Paragraph: React.FC<ParagraphProps> = ({
  children,
  extend,
  allowHtml = false,
}) => {
  const { styles } = useFelaEnhanced(felaRules, { extend });

  return React.createElement(`p`, {
    className: styles.container,
    ...(allowHtml && {
      dangerouslySetInnerHTML: {
        __html: children,
      },
    }),
    ...(!allowHtml && {
      children,
    }),
  });
};

export default Paragraph;
