import React from 'react';
import { PageProps } from 'gatsby';
import Paragraph from '../components/Paragraph';
import useFelaEnhanced from '../hooks/useFelaEnhanced';

const felaRules = {
  container: () => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    height: `100vh`,
  }),
};

const NotFound: React.FC<PageProps> = () => {
  const { styles } = useFelaEnhanced(felaRules);

  return (
    <main className={styles.container}>
      <Paragraph>Sorry, page not found!</Paragraph>
    </main>
  );
};

export default NotFound;
