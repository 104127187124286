import { TRuleWithTheme } from '../../gatsby-theme-fela/styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: `1rem`,
  lineHeight: `1.65rem`,
  wordBreak: `break-word`,

  tabletLarge: {
    fontSize: `1.5rem`,
    lineHeight: `2rem`,
  },

  letterSpacing: `1.09px`,
  fontWeight: 300,
  margin: 0,
});
